import React from "react";
import UtagOverride from "./utag-override";
import TealiumUniversalTag from "./tealium-universal-tag";
import UtagView from "./utag-view";

const TealiumScripts = () => {
  // NOTE: I am loading each of the Tealium JS snippets as individual script tags in the DOM
  // so that they will hopefully load sequentially, i.e. the utag override will happen first,
  // then invoke the utag.js, and finally call utag.view() with the page utag_data object.

  return (
    <>
      <UtagOverride />
      <TealiumUniversalTag />
      <UtagView />
    </>
  );
};

export default TealiumScripts;
