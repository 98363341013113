import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";

const PageUtagDataContext = React.createContext({ utag_data: null });

function PageUtagDataProvider({ children }) {
  const [utag_data, setUtagData] = useState({
    page_section: null,
    page_category: null,
    page_name: null,
    tealium_event: null,
    site_language: null
  });

  // we are only concerned with the pathname
  let path = useLocation().pathname;
  // Remove trailing slash (/) from the URL if it exists
  path = path.replace(/\/$/, "");
  // Now explicitly add a trailing slash to act as a word break for ".../es..." and the homepage "/"
  path = path + "/";

  // NOTE: the "/" will be mapped to "homepage" explicitly outside of these section dictionaries.
  // English sections
  const sectionsEN = {
    // "/": 'homepage',
    "/personal-banking": "personal-banking",
    "/business-banking": "business-banking",
    "/commercial-banking": "commercial-banking",
    "/locations": "locations",
    "/make-an-appointment": "appointment",
    "/blog": "articles",
    "about-us": "about-us",
    "customer-service": "customer-service"
  };

  // Spanish sections
  const sectionsES = {
    // "/": 'homepage',
    "/es/banca-personal": "personal-banking",
    //"/business-banking": 'business-banking',
    //"/commercial-banking": 'commercial-banking',
    "/es/sucursales": "locations",
    //"/make-an-appointment": 'appointment',
    //"/blog": 'articles',
    "/es/contactanos": "about-us",
    "/es/detalles-de-la-cuenta": "customer-service",
    "/es/preguntas-frecuentes": "customer-service"
  };

  useEffect(() => {
    let tempData = {
      page_section: "other",
      page_category: "unknown",
      page_name: "unknown",
      tealium_event: "unknown",
      site_language: "english"
    };

    //console.log(`path: "${path}"`);

    // set the site_language
    if (path.includes("/es/")) {
      tempData.site_language = "spanish";
    }

    // set the page_section
    if (path === "/") {
      tempData.page_section = "homepage";
    } else {
      const sectionsMap = tempData.site_language === "english" ? { ...sectionsEN } : { ...sectionsES };

      for (const key in sectionsMap) {
        if (path.includes(key)) {
          tempData.page_section = sectionsMap[key];
          break;
        }
      }
    }

    // strip leading and trailing slashes
    let newPath = path.replace(/^\//, "").replace(/\/$/, "");

    // parse the path to get page_name and page_category
    const pageUrlParts = newPath.split("/");
    if (pageUrlParts && pageUrlParts[0] !== "") {
      //console.log("pageUrlParts: ", pageUrlParts);
      const count = pageUrlParts.length;
      //console.log("count: " + count);
      if (count >= 3) {
        tempData.page_name = pageUrlParts[count - 1];
        tempData.page_category = pageUrlParts[count - 2];
      } else if (count === 2) {
        tempData.page_name = pageUrlParts[1];
        tempData.page_category = pageUrlParts[0];
      } else if (count === 1) {
        tempData.page_name = pageUrlParts[0];
        tempData.page_category = tempData.page_section;
      } else {
        tempData.page_name = tempData.page_section;
        tempData.page_category = tempData.page_section;
      }
    } else {
      tempData.page_name = tempData.page_section;
      tempData.page_category = tempData.page_section;
    }

    // set tealium_event to be the same as the page_name
    tempData.tealium_event = tempData.page_section;

    //console.log("utag_data: ", tempData);
    setUtagData({ ...tempData });
  }, []);

  return <PageUtagDataContext.Provider value={utag_data}>{children}</PageUtagDataContext.Provider>;
}

function usePageUtagDataContext() {
  const context = React.useContext(PageUtagDataContext);
  if (context === undefined) {
    throw new Error("usePageUtagDataContext must be used within a PageUtagDataProvider");
  }
  return context;
}

export { PageUtagDataProvider, usePageUtagDataContext };