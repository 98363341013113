const englishToSpanish = {
  "": "/es",
  "/personal-banking/login": "/es/banca-personal/entrar",
  "/locations": "/es/sucursales",
  "/open-bank-account-online": "/es/abrir-cuenta-bancaria-en-linea",
  "/personal-banking/checking-account": "/es/banca-personal/cuenta-de-cheques",
  "/personal-banking/free-checking-account": "/es/banca-personal/gratis-cuenta-de-cheques",
  "/personal-banking/credit-cards": "/es/banca-personal/tarjetas-de-credito",
  "/personal-banking/interest-rates": "/es/banca-personal/tasas-de-interes",
  "/personal-banking/savings-account/cds-tx": "/es/banca-personal/tasas-de-interes-texas",
  "/personal-banking/savings-account": "/es/banca-personal/cuentas-de-ahorro",
  "/personal-banking/retirement-ira-accounts": "/es/banca-personal/jubilacion-ira",
  "/personal-banking/home-loans": "/es/banca-personal/prestamos-hipotecarios",
  "/personal-banking/home-loans/mortgage-faq": "/es/banca-personal/prestamos-hipotecarios/preguntas-frecuentes",
  "/personal-banking/home-loans/closing-cost-checking-account-offer":
    "/es/banca-personal/prestamos-hipotecarios/ahorre-en-costos-de-cierre",
  "/personal-banking/home-loans/mortgage-rates": "/es/banca-personal/tasas-de-interes-hipotecarias-actuales",
  "/personal-banking/contact-loan-officer": "/es/banca-personal/contacte-a-un-asesor-hipotecario",
  "/personal-banking/home-loans/homeowner-assistance":
    "/es/banca-personal/prestamos-hipotecarios/asistencia-a-propietarios",
  "/personal-banking/home-loans/first-time-home-buyer-loans": "/es/banca-personal/prestamos-hipotecarios",
  "/personal-banking/home-loans/home-mortgage": "/es/banca-personal/prestamos-hipotecarios",
  "/personal-banking/home-loans/mortgage-refinance": "/es/banca-personal/prestamos-hipotecarios",
  "/personal-banking/home-loans/home-improvement-loans": "/es/banca-personal/prestamos-hipotecarios",
  "/personal-banking/home-loans/construction-loans": "/es/banca-personal/prestamos-hipotecarios",
  "/personal-banking/home-loans/land-loans":
    "/es/banca-personal/prestamos-hipotecarios/prestamos-para-lotes-y-terrenos",
  "/personal-banking/home-loans/heloc-home-equity-line-of-credit": "/es/banca-personal/prestamos-hipotecarios",
  "/personal-banking/account-fees": "/es/banca-personal/cargos",
  "/personal-banking/checking-account/rewards-checking": "/es/banca-personal/rewards-checking",
  "/customer-service/account-details": "/es/detalles-de-la-cuenta",
  "/about-us/contact-us": "/es/contactanos",
  "/customer-service/faq": "/es/preguntas-frecuentes",
  "/terms-of-use": "/es/terminos-de-uso",
  "/banking-privacy-security": "/es/privacidad-y-seguridad",
  "/banking-privacy-security/online-privacy-policy": "/es/privacidad-y-seguridad/privacidad-en-linea",
  "/banking-privacy-security/privacy-faq": "/es/privacidad-y-seguridad/preguntas-frecuentes-sobre-privacidad",
  "/banking-privacy-security/security-faq": "/es/privacidad-y-seguridad/preguntas-frecuentes-sobre-seguridad",
  "/locations/washington": "/es/sucursales/washington",
  "/locations/washington/leavenworth": "/es/sucursales/washington/leavenworth",
  "/locations/washington/wenatchee": "/es/sucursales/washington/wenatchee",
  "/locations/washington/chelan": "/es/sucursales/washington/chelan",
  "/locations/washington/cle-elum": "/es/sucursales/washington/cle-elum",
  "/locations/washington/quincy": "/es/sucursales/washington/quincy",
  "/locations/washington/monroe": "/es/sucursales/washington/monroe",
  "/locations/washington/sammamish": "/es/sucursales/washington/sammamish",
  "/locations/washington/issaquah": "/es/sucursales/washington/issaquah",
  "/locations/washington/snohomish": "/es/sucursales/washington/snohomish",
  "/locations/washington/redmond": "/es/sucursales/washington/redmond",
  "/locations/washington/bellevue": "/es/sucursales/washington/bellevue",
  "/locations/washington/bellevue/crossroads": "/es/sucursales/washington/bellevue/crossroads",
  "/locations/washington/bellevue/bel-red-road": "/es/sucursales/washington/bellevue/bel-red-road",
  "/locations/washington/bellevue/eastgate": "/es/sucursales/washington/bellevue/eastgate",
  "/locations/washington/bellevue/downtown": "/es/sucursales/washington/bellevue/downtown",
  "/locations/washington/bellevue/west": "/es/sucursales/washington/bellevue/west",
  "/locations/washington/woodinville": "/es/sucursales/washington/woodinville",
  "/locations/washington/bothell": "/es/sucursales/washington/bothell",
  "/locations/washington/kirkland/juanita": "/es/sucursales/washington/kirkland/juanita",
  "/locations/washington/kirkland/kirkland-ave": "/es/sucursales/washington/kirkland/kirkland-ave",
  "/locations/washington/everett/bothell-everett-way": "/es/sucursales/washington/everett/bothell-everett-way",
  "/locations/washington/bothell/canyon-park-place": "/es/sucursales/washington/bothell/canyon-park-place",
  "/locations/washington/marysville": "/es/sucursales/washington/marysville",
  "/locations/washington/everett/evergreen-way": "/es/sucursales/washington/everett/evergreen-way",
  "/locations/washington/seattle/wedgwood": "/es/sucursales/washington/seattle/wedgwood",
  "/locations/washington/omak": "/es/sucursales/washington/omak",
  "/locations/washington/seattle/rainier-beach": "/es/sucursales/washington/seattle/rainier-beach",
  "/locations/washington/kent": "/es/sucursales/washington/kent",
  "/locations/washington/seattle/jackson-park": "/es/sucursales/washington/seattle/jackson-park",
  "/locations/washington/lynnwood": "/es/sucursales/washington/lynnwood",
  "/locations/washington/seattle/columbia-city": "/es/sucursales/washington/seattle/columbia-city",
  "/locations/washington/seattle/u-district": "/es/sucursales/washington/seattle/u-district",
  "/locations/washington/seattle/beacon-hill": "/es/sucursales/washington/seattle/beacon-hill",
  "/locations/washington/seattle/international-district": "/es/sucursales/washington/seattle/international-district",
  "/locations/washington/seattle/downtown": "/es/sucursales/washington/seattle/downtown",
  "/locations/washington/auburn": "/es/sucursales/washington/auburn",
  "/locations/washington/seattle/crown-hill": "/es/sucursales/washington/seattle/crown-hill",
  "/locations/washington/edmonds": "/es/sucursales/washington/edmonds",
  "/locations/washington/seattle/ballard": "/es/sucursales/washington/seattle/ballard",
  "/locations/washington/seattle/magnolia": "/es/sucursales/washington/seattle/magnolia",
  "/locations/washington/seattle/california-ave-dakota": "/es/sucursales/washington/seattle/california-ave-dakota",
  "/locations/washington/federal-way": "/es/sucursales/washington/federal-way",
  "/locations/washington/yakima": "/es/sucursales/washington/yakima",
  "/locations/washington/kennewick": "/es/sucursales/washington/kennewick",
  "/locations/washington/moses-lake": "/es/sucursales/washington/moses-lake",
  "/locations/washington/stanwood": "/es/sucursales/washington/stanwood",
  "/locations/washington/bainbridge-island": "/es/sucursales/washington/bainbridge-island",
  "/locations/washington/puyallup": "/es/sucursales/washington/puyallup",
  "/locations/washington/mount-vernon": "/es/sucursales/washington/mount-vernon",
  "/locations/washington/poulsbo": "/es/sucursales/washington/poulsbo",
  "/locations/washington/bremerton": "/es/sucursales/washington/bremerton",
  "/locations/washington/burlington": "/es/sucursales/washington/burlington",
  "/locations/washington/university-place": "/es/sucursales/washington/university-place",
  "/locations/washington/lakewood": "/es/sucursales/washington/lakewood",
  "/locations/washington/la-conner": "/es/sucursales/washington/la-conner",
  "/locations/washington/oak-harbor": "/es/sucursales/washington/oak-harbor",
  "/locations/washington/odessa": "/es/sucursales/washington/odessa",
  "/locations/washington/anacortes": "/es/sucursales/washington/anacortes",
  "/locations/washington/bellingham/lakeway-drive": "/es/sucursales/washington/bellingham/lakeway-drive",
  "/locations/washington/bellingham/barkley-blvd": "/es/sucursales/washington/bellingham/barkley-blvd",
  "/locations/washington/bellingham/cornwall-ave": "/es/sucursales/washington/bellingham/cornwall-ave",
  "/locations/washington/bellingham/bakerview": "/es/sucursales/washington/bellingham/bakerview",
  "/locations/washington/lacey": "/es/sucursales/washington/lacey",
  "/locations/washington/republic": "/es/sucursales/washington/republic",
  "/locations/washington/sequim": "/es/sucursales/washington/sequim",
  "/locations/washington/ferndale": "/es/sucursales/washington/ferndale",
  "/locations/washington/lynden": "/es/sucursales/washington/lynden",
  "/locations/washington/olympia": "/es/sucursales/washington/olympia",
  "/locations/washington/eastsound": "/es/sucursales/washington/eastsound",
  "/locations/washington/blaine": "/es/sucursales/washington/blaine",
  "/locations/washington/centralia": "/es/sucursales/washington/centralia",
  "/locations/washington/colville": "/es/sucursales/washington/colville",
  "/locations/washington/chewelah": "/es/sucursales/washington/chewelah",
  "/locations/washington/deer-park": "/es/sucursales/washington/deer-park",
  "/locations/washington/spokane": "/es/sucursales/washington/spokane",
  "/locations/washington/walla-walla": "/es/sucursales/washington/walla-walla",
  "/locations/washington/dayton": "/es/sucursales/washington/dayton",
  "/locations/washington/colfax": "/es/sucursales/washington/colfax",
  "/locations/washington/vancouver": "/es/sucursales/washington/vancouver",
  "/locations/washington/newport": "/es/sucursales/washington/newport",
  "/locations/washington/pullman": "/es/sucursales/washington/pullman",
  "/locations/washington/clarkston": "/es/sucursales/washington/clarkston",
  "/locations/oregon": "/es/sucursales/oregon",
  "/locations/oregon/prineville": "/es/sucursales/oregon/prineville",
  "/locations/oregon/bend/purcell-blvd": "/es/sucursales/oregon/bend/purcell-blvd",
  "/locations/oregon/bend/bluff-drive": "/es/sucursales/oregon/bend/bluff-drive",
  "/locations/oregon/redmond": "/es/sucursales/oregon/redmond",
  "/locations/oregon/la-pine": "/es/sucursales/oregon/la-pine",
  "/locations/oregon/sisters": "/es/sucursales/oregon/sisters",
  "/locations/oregon/madras": "/es/sucursales/oregon/madras",
  "/locations/oregon/lakeview": "/es/sucursales/oregon/lakeview",
  "/locations/oregon/klamath-falls/campus": "/es/sucursales/oregon/klamath-falls/campus",
  "/locations/oregon/klamath-falls/sixth-street": "/es/sucursales/oregon/klamath-falls/sixth-street",
  "/locations/oregon/klamath-falls/main-street": "/es/sucursales/oregon/klamath-falls/main-street",
  "/locations/oregon/eugene/coburg-road": "/es/sucursales/oregon/eugene/coburg-road",
  "/locations/oregon/eugene/downtown": "/es/sucursales/oregon/eugene/downtown",
  "/locations/oregon/the-dalles": "/es/sucursales/oregon/the-dalles",
  "/locations/oregon/albany": "/es/sucursales/oregon/albany",
  "/locations/oregon/hood-river": "/es/sucursales/oregon/hood-river",
  "/locations/oregon/corvallis": "/es/sucursales/oregon/corvallis",
  "/locations/oregon/salem/broadway-street": "/es/sucursales/oregon/salem/broadway-street",
  "/locations/oregon/salem/wallace-road": "/es/sucursales/oregon/salem/wallace-road",
  "/locations/oregon/woodburn": "/es/sucursales/oregon/woodburn",
  "/locations/oregon/white-city": "/es/sucursales/oregon/white-city",
  "/locations/oregon/gresham": "/es/sucursales/oregon/gresham",
  "/locations/oregon/wilsonville": "/es/sucursales/oregon/wilsonville",
  "/locations/oregon/monmouth": "/es/sucursales/oregon/monmouth",
  "/locations/oregon/lake-oswego": "/es/sucursales/oregon/lake-oswego",
  "/locations/oregon/hermiston": "/es/sucursales/oregon/hermiston",
  "/locations/oregon/medford/biddle-road": "/es/sucursales/oregon/medford/biddle-road",
  "/locations/oregon/ashland": "/es/sucursales/oregon/ashland",
  "/locations/oregon/tigard": "/es/sucursales/oregon/tigard",
  "/locations/oregon/dallas": "/es/sucursales/oregon/dallas",
  "/locations/oregon/portland/downtown": "/es/sucursales/oregon/portland/downtown",
  "/locations/oregon/portland/raleigh-hills": "/es/sucursales/oregon/portland/raleigh-hills",
  "/locations/oregon/la-grande": "/es/sucursales/oregon/la-grande",
  "/locations/oregon/grants-pass/north-valley": "/es/sucursales/oregon/grants-pass/north-valley",
  "/locations/oregon/grants-pass/city-centre": "/es/sucursales/oregon/grants-pass/city-centre",
  "/locations/oregon/hillsboro": "/es/sucursales/oregon/hillsboro",
  "/locations/oregon/grants-pass/south-river": "/es/sucursales/oregon/grants-pass/south-river",
  "/locations/oregon/florence": "/es/sucursales/oregon/florence",
  "/locations/oregon/ontario": "/es/sucursales/oregon/ontario",
  "/locations/oregon/waldport": "/es/sucursales/oregon/waldport",
  "/locations/oregon/milton-freewater": "/es/sucursales/oregon/milton-freewater",
  "/locations/oregon/newport": "/es/sucursales/oregon/newport",
  "/locations/oregon/lincoln-city": "/es/sucursales/oregon/lincoln-city",
  "/locations/oregon/cave-junction": "/es/sucursales/oregon/cave-junction",
  "/locations/idaho": "/es/sucursales/idaho",
  "/locations/idaho/ketchum": "/es/sucursales/idaho/ketchum",
  "/locations/idaho/hailey": "/es/sucursales/idaho/hailey",
  "/locations/idaho/gooding": "/es/sucursales/idaho/gooding",
  "/locations/idaho/boise/west-idaho-street": "/es/sucursales/idaho/boise/west-idaho-street",
  "/locations/idaho/boise/federal-way": "/es/sucursales/idaho/boise/federal-way",
  "/locations/idaho/boise/cole-road": "/es/sucursales/idaho/boise/cole-road",
  "/locations/idaho/boise/towne-square": "/es/sucursales/idaho/boise/towne-square",
  "/locations/idaho/boise/fairview-ave": "/es/sucursales/idaho/boise/fairview-ave",
  "/locations/idaho/eagle": "/es/sucursales/idaho/eagle",
  "/locations/idaho/salmon": "/es/sucursales/idaho/salmon",
  "/locations/idaho/meridian": "/es/sucursales/idaho/meridian",
  "/locations/idaho/mccall": "/es/sucursales/idaho/mccall",
  "/locations/idaho/jerome": "/es/sucursales/idaho/jerome",
  "/locations/idaho/nampa/11th-ave": "/es/sucursales/idaho/nampa/11th-ave",
  "/locations/idaho/nampa/marketplace": "/es/sucursales/idaho/nampa/marketplace",
  "/locations/idaho/caldwell": "/es/sucursales/idaho/caldwell",
  "/locations/idaho/twin-falls": "/es/sucursales/idaho/twin-falls",
  "/locations/idaho/payette": "/es/sucursales/idaho/payette",
  "/locations/idaho/blackfoot": "/es/sucursales/idaho/blackfoot",
  "/locations/idaho/pocatello": "/es/sucursales/idaho/pocatello",
  "/locations/idaho/idaho-falls": "/es/sucursales/idaho/idaho-falls",
  "/locations/idaho/rexburg": "/es/sucursales/idaho/rexburg",
  "/locations/idaho/silver-valley": "/es/sucursales/idaho/silver-valley",
  "/locations/idaho/sandpoint": "/es/sucursales/idaho/sandpoint",
  "/locations/nevada": "/es/sucursales/nevada",
  "/locations/nevada/ely": "/es/sucursales/nevada/ely",
  "/locations/nevada/fallon": "/es/sucursales/nevada/fallon",
  "/locations/nevada/elko": "/es/sucursales/nevada/elko",
  "/locations/nevada/fernley": "/es/sucursales/nevada/fernley",
  "/locations/nevada/north-las-vegas": "/es/sucursales/nevada/north-las-vegas",
  "/locations/nevada/mesquites": "/es/sucursales/nevada/mesquites",
  "/locations/nevada/overton": "/es/sucursales/nevada/overton",
  "/locations/nevada/las-vegas/summerlin": "/es/sucursales/nevada/las-vegas/summerlin",
  "/locations/nevada/las-vegas/rhodes-ranch": "/es/sucursales/nevada/las-vegas/rhodes-ranch",
  "/locations/nevada/henderson": "/es/sucursales/nevada/henderson",
  "/locations/utah": "/es/sucursales/utah",
  "/locations/utah/price": "/es/sucursales/utah/price",
  "/locations/utah/draper": "/es/sucursales/utah/draper",
  "/locations/utah/murray": "/es/sucursales/utah/murray",
  "/locations/utah/salt-lake-city/cottonwood": "/es/sucursales/utah/salt-lake-city/cottonwood",
  "/locations/utah/salt-lake-city/sugarhouse": "/es/sucursales/utah/salt-lake-city/sugarhouse",
  "/locations/utah/salt-lake-city/foothill-drive": "/es/sucursales/utah/salt-lake-city/foothill-drive",
  "/locations/utah/salt-lake-city/plaza": "/es/sucursales/utah/salt-lake-city/plaza",
  "/locations/utah/layton": "/es/sucursales/utah/layton",
  "/locations/utah/logan": "/es/sucursales/utah/logan",
  "/locations/arizona": "/es/sucursales/arizona",
  "/locations/arizona/payson": "/es/sucursales/arizona/payson",
  "/locations/arizona/fountain-hills": "/es/sucursales/arizona/fountain-hills",
  "/locations/arizona/globe": "/es/sucursales/arizona/globe",
  "/locations/arizona/mesa/mckellips-road": "/es/sucursales/arizona/mesa/mckellips-road",
  "/locations/arizona/mesa/superstition-springs": "/es/sucursales/arizona/mesa/superstition-springs",
  "/locations/arizona/phoenix/paradise-valley": "/es/sucursales/arizona/phoenix/paradise-valley",
  "/locations/arizona/scottsdale": "/es/sucursales/arizona/scottsdale",
  "/locations/arizona/show-low": "/es/sucursales/arizona/show-low",
  "/locations/arizona/gilbert": "/es/sucursales/arizona/gilbert",
  "/locations/arizona/phoenix/camelback-road": "/es/sucursales/arizona/phoenix/camelback-road",
  "/locations/arizona/glendale": "/es/sucursales/arizona/glendale",
  "/locations/arizona/sun-city": "/es/sucursales/arizona/sun-city",
  "/locations/arizona/sun-city-west": "/es/sucursales/arizona/sun-city-west",
  "/locations/arizona/prescott-valley": "/es/sucursales/arizona/prescott-valley",
  "/locations/arizona/goodyear": "/es/sucursales/arizona/goodyear",
  "/locations/arizona/safford": "/es/sucursales/arizona/safford",
  "/locations/arizona/tucson/oracle-and-magee": "/es/sucursales/arizona/tucson/oracle-and-magee",
  "/locations/arizona/tucson/sunrise-drive": "/es/sucursales/arizona/tucson/sunrise-drive",
  "/locations/arizona/tucson/flowing-wells": "/es/sucursales/arizona/tucson/flowing-wells",
  "/locations/arizona/tucson/grant-and-craycroft": "/es/sucursales/arizona/tucson/grant-and-craycroft",
  "/locations/arizona/tucson/4910-broadway-blvd": "/es/sucursales/arizona/tucson/4910-broadway-blvd",
  "/locations/arizona/tucson/camino-seco": "/es/sucursales/arizona/tucson/camino-seco",
  "/locations/arizona/tucson/campbell": "/es/sucursales/arizona/tucson/campbell",
  "/locations/arizona/tucson/south-tucson": "/es/sucursales/arizona/tucson/south-tucson",
  "/locations/arizona/green-valley": "/es/sucursales/arizona/green-valley",
  "/locations/arizona/benson": "/es/sucursales/arizona/benson",
  "/locations/arizona/nogales": "/es/sucursales/arizona/nogales",
  "/locations/arizona/bisbee": "/es/sucursales/arizona/bisbee",
  "/locations/arizona/douglas": "/es/sucursales/arizona/douglas",
  "/locations/arizona/yuma/fortuna": "/es/sucursales/arizona/yuma/fortuna",
  "/locations/arizona/yuma/4th-ave": "/es/sucursales/arizona/yuma/4th-ave",
  "/locations/arizona/surprise": "/es/sucursales/arizona/surprise",
  "/locations/new-mexico": "/es/sucursales/nuevo-mexico",
  "/locations/new-mexico/albuquerque/eubank-blvd": "/es/sucursales/nuevo-mexico/albuquerque/eubank-blvd",
  "/locations/new-mexico/albuquerque/osuna-road": "/es/sucursales/nuevo-mexico/albuquerque/osuna-road",
  "/locations/new-mexico/albuquerque/west-albuquerque": "/es/sucursales/nuevo-mexico/albuquerque/west-albuquerque",
  "/locations/new-mexico/socorro": "/es/sucursales/nuevo-mexico/socorro",
  "/locations/new-mexico/rio-rancho": "/es/sucursales/nuevo-mexico/rio-rancho",
  "/locations/new-mexico/capitan": "/es/sucursales/nuevo-mexico/capitan",
  "/locations/new-mexico/santa-fe/st-michaels-drive": "/es/sucursales/nuevo-mexico/santa-fe/st-michaels-drive",
  "/locations/new-mexico/santa-fe/downtown": "/es/sucursales/nuevo-mexico/santa-fe/downtown",
  "/locations/new-mexico/ruidoso": "/es/sucursales/nuevo-mexico/ruidoso",
  "/locations/new-mexico/espanola": "/es/sucursales/nuevo-mexico/espanola",
  "/locations/new-mexico/roswell/north-main-street": "/es/sucursales/nuevo-mexico/roswell/north-main-street",
  "/locations/new-mexico/roswell/pennsylvania-ave": "/es/sucursales/nuevo-mexico/roswell/pennsylvania-ave",
  "/locations/new-mexico/roswell/south-main-street": "/es/sucursales/nuevo-mexico/roswell/south-main-street",
  "/locations/new-mexico/alamogordo": "/es/sucursales/nuevo-mexico/alamogordo",
  "/locations/new-mexico/angel-fire": "/es/sucursales/nuevo-mexico/angel-fire",
  "/locations/new-mexico/artesia": "/es/sucursales/nuevo-mexico/artesia",
  "/locations/new-mexico/clovis": "/es/sucursales/nuevo-mexico/clovis",
  "/locations/new-mexico/las-cruces/amador-ave": "/es/sucursales/nuevo-mexico/las-cruces/amador-ave",
  "/locations/new-mexico/chama": "/es/sucursales/nuevo-mexico/chama",
  "/locations/new-mexico/gallup": "/es/sucursales/nuevo-mexico/gallup",
  "/locations/new-mexico/raton": "/es/sucursales/nuevo-mexico/raton",
  "/locations/new-mexico/silver-city/hudson-street": "/es/sucursales/nuevo-mexico/silver-city/hudson-street",
  "/locations/new-mexico/silver-city/11th-street": "/es/sucursales/nuevo-mexico/silver-city/11th-street",
  "/locations/new-mexico/hobbs": "/es/sucursales/nuevo-mexico/hobbs",
  "/locations/new-mexico/farmington": "/es/sucursales/nuevo-mexico/farmington",
  "/locations/texas": "/es/sucursales/texas",
  "/locations/texas/denton": "/es/sucursales/texas/denton",
  "/locations/texas/dallas/lovers-lane": "/es/sucursales/texas/dallas/lovers-lane",
  "/locations/texas/dallas/midtown": "/es/sucursales/texas/dallas/midtown",
  "/locations/texas/plano": "/es/sucursales/texas/plano",
  "/locations/arizona/mesa": "/es/sucursales/arizona/mesa",
  "/locations/arizona/phoenix": "/es/sucursales/arizona/phoenix",
  "/locations/arizona/tucson": "/es/sucursales/arizona/tucson",
  "/locations/arizona/yuma": "/es/sucursales/arizona/yuma",
  "/locations/idaho/boise": "/es/sucursales/idaho/boise",
  "/locations/idaho/nampa": "/es/sucursales/idaho/nampa",
  "/locations/new-mexico/albuquerque": "/es/sucursales/nuevo-mexico/albuquerque",
  "/locations/new-mexico/las-cruces": "/es/sucursales/nuevo-mexico/las-cruces",
  "/locations/new-mexico/roswell": "/es/sucursales/nuevo-mexico/roswell",
  "/locations/new-mexico/santa-fe": "/es/sucursales/nuevo-mexico/santa-fe",
  "/locations/new-mexico/silver-city": "/es/sucursales/nuevo-mexico/silver-city",
  "/locations/oregon/bend": "/es/sucursales/oregon/bend",
  "/locations/oregon/eugene": "/es/sucursales/oregon/eugene",
  "/locations/oregon/grants-pass": "/es/sucursales/oregon/grants-pass",
  "/locations/oregon/klamath-falls": "/es/sucursales/oregon/klamath-falls",
  "/locations/oregon/medford": "/es/sucursales/oregon/medford",
  "/locations/oregon/portland": "/es/sucursales/oregon/portland",
  "/locations/oregon/salem": "/es/sucursales/oregon/salem",
  "/locations/texas/dallas": "/es/sucursales/texas/dallas",
  "/locations/utah/salt-lake-city": "/es/sucursales/utah/salt-lake-city",
  "/locations/washington/bellingham": "/es/sucursales/washington/bellingham",
  "/locations/washington/everett": "/es/sucursales/washington/everett",
  "/locations/washington/kirkland": "/es/sucursales/washington/kirkland",
  "/locations/washington/seattle": "/es/sucursales/washington/seattle",
  "/locations/nevada/las-vegas": "/es/sucursales/nevada/las-vegas",
  "/locations/texas/austin": "/es/sucursales/texas/austin",
  "/locations/new-mexico/albuquerque/albuquerque-plaza": "/es/sucursales/nuevo-mexico/albuquerque/albuquerque-plaza",
  "/locations/california/santa-rosa": "/es/sucursales/california/santa-rosa",
  "/locations/california/beverly-hills": "/es/sucursales/california/beverly-hills",
  "/locations/california/los-altos": "/es/sucursales/california/los-altos",
  "/locations/california/el-segundo": "/es/sucursales/california/el-segundo",
  "/locations/california/encino": "/es/sucursales/california/encino",
  "/locations/california/long-beach": "/es/sucursales/california/long-beach",
  "/locations/california/pasadena": "/es/sucursales/california/pasadena",
  "/locations/california/san-jose": "/es/sucursales/california/san-jose",
  "/locations/california/san-rafael": "/es/sucursales/california/san-rafael",
  "/locations/california/burbank-toluca-lake": "/es/sucursales/california/burbank-toluca-lake",
  "/locations/washington/bellevue/central": "/es/sucursales/washington/bellevue/central",
  "/locations/california": "/es/sucursales/california",
  "/blog": "/es/tu-decides",
  "/blog/family-finance/saving-for-college": "/es/tu-decides/ahorrar-para-la-universidad",
  "/blog/consumer-tips/what-to-do-with-tax-refund": "/es/tu-decides/que-hacer-con-el-retorno-de-impuestos-anual",
  "/blog/banking-101/top-budgeting-tips": "/es/tu-decides/mejores-consejos-para-presupuestar",
  "/blog/family-finance/ideas-financial-literacy-kids": "/es/tu-decides/ensenar-a-los-hijos-sobre-el-dinero",
  "/blog/consumer-tips/financial-fitness": "/es/tu-decides/como-mantener-finanzas-saludables",
  "/blog/building-credit/how-to-boost-your-credit-score": "/es/tu-decides/como-aumentar-su-puntaje-crediticio",
  "/blog/consumer-tips/boost-your-savings": "/es/tu-decides/como-lograr-este-objetivo-de-ahorro",
  "/blog/consumer-tips/year-end-good-habits": "/es/tu-decides/termina-anualmente-con-buena-salud-financiera",
  "/blog/consumer-tips/cutting-costs-around-the-house": "/es/tu-decides/consejos-para-recortar-gastos-en-casa",
  "/blog/home-ownership/best-age-to-buy-a-house": "/es/tu-decides/compra-tu-casa",
  "/blog/home-ownership/closing-costs": "/es/tu-decides/costos-de-cierre",
  "/blog/home-ownership/selling-your-home": "/es/tu-decides/vender-su-casa",
  "/blog/home-ownership/new-home-checklist": "/es/tu-decides/lista-de-verificacion-de-casa-nueva",
  "/blog/banking-101/prepare-for-economic-uncertainties":
    "/es/tu-decides/prepararse-para-las-incertidumbres-economicas",
  "/workplace-banking": "/es/work-perks",
  "/personal-banking/home-loans/smart-start-down-payment-assistance":
    "/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start",
  "/locations/nevada/las-vegas/aliante": "/es/sucursales/nevada/las-vegas/aliante",
  "/locations/nevada/mesquite": "/es/sucursales/nevada/mesquite",
  "/locations/nevada/las-vegas/park-place": "/es/sucursales/nevada/las-vegas/park-place",
  "/locations/oregon/portland/burnside": "/es/sucursales/oregon/portland/burnside",
  "/locations/washington/bothell/183rd-street": "/es/sucursales/washington/bothell/183rd-street",
  "/personal-banking/fresh-start-second-chance-account": "/es/banca-personal/cuenta-nuevo-comienzo",
  "/business-banking/small-business": "/es/banca-de-negocios",
  "/personal-banking/rewards-checking-faq":
    "/es/banca-personal/preguntas-frecuentes-sobre-los-rewards-de-cuentas-de-cheques",
  "/personal-banking/checking-account/interest-rewards-checking": "/es/banca-personal/interest-rewards-checking",
  "/blog/consumer-tips/how-to-write-a-check": "/es/tu-decides/como-escribir-un-cheque"
};

const spanishToEnglish = {
  "/es": "/",
  "/es/banca-personal/entrar": "/personal-banking/login",
  "/es/sucursales": "/locations",
  "/es/abrir-cuenta-bancaria-en-linea": "/open-bank-account-online",
  "/es/banca-personal/cuenta-de-cheques": "/personal-banking/checking-account",
  "/es/banca-personal/gratis-cuenta-de-cheques": "/personal-banking/free-checking-account",
  "/es/banca-personal/tarjetas-de-credito": "/personal-banking/credit-cards",
  "/es/banca-personal/tasas-de-interes": "/personal-banking/interest-rates",
  "/es/banca-personal/tasas-de-interes-texas": "/personal-banking/savings-account/cds-tx",
  "/es/banca-personal/cuentas-de-ahorro": "/personal-banking/savings-account",
  "/es/banca-personal/jubilacion-ira": "/personal-banking/retirement-ira-accounts",
  "/es/banca-personal/prestamos-hipotecarios": "/personal-banking/home-loans",
  "/es/banca-personal/prestamos-hipotecarios/preguntas-frecuentes": "/personal-banking/home-loans/mortgage-faq",
  "/es/banca-personal/prestamos-hipotecarios/ahorre-en-costos-de-cierre":
    "/personal-banking/home-loans/closing-cost-checking-account-offer",
  "/es/banca-personal/tasas-de-interes-hipotecarias-actuales": "/personal-banking/home-loans/mortgage-rates",
  "/es/banca-personal/contacte-a-un-asesor-hipotecario": "/personal-banking/contact-loan-officer",
  "/es/banca-personal/prestamos-hipotecarios/asistencia-a-propietarios":
    "/personal-banking/home-loans/homeowner-assistance",
  "/es/banca-personal/cargos": "/personal-banking/account-fees",
  "/es/banca-personal/rewards-checking": "/personal-banking/checking-account/rewards-checking",
  "/es/detalles-de-la-cuenta": "/customer-service/account-details",
  "/es/contactanos": "/about-us/contact-us",
  "/es/preguntas-frecuentes": "/customer-service/faq",
  "/es/terminos-de-uso": "/terms-of-use",
  "/es/privacidad-y-seguridad": "/banking-privacy-security",
  "/es/privacidad-y-seguridad/privacidad-en-linea": "/banking-privacy-security/online-privacy-policy",
  "/es/privacidad-y-seguridad/preguntas-frecuentes-sobre-privacidad": "/banking-privacy-security/privacy-faq",
  "/es/privacidad-y-seguridad/preguntas-frecuentes-sobre-seguridad": "/banking-privacy-security/security-faq",
  "/es/sucursales/washington": "/locations/washington",
  "/es/sucursales/washington/leavenworth": "/locations/washington/leavenworth",
  "/es/sucursales/washington/wenatchee": "/locations/washington/wenatchee",
  "/es/sucursales/washington/chelan": "/locations/washington/chelan",
  "/es/sucursales/washington/cle-elum": "/locations/washington/cle-elum",
  "/es/sucursales/washington/quincy": "/locations/washington/quincy",
  "/es/sucursales/washington/monroe": "/locations/washington/monroe",
  "/es/sucursales/washington/sammamish": "/locations/washington/sammamish",
  "/es/sucursales/washington/issaquah": "/locations/washington/issaquah",
  "/es/sucursales/washington/snohomish": "/locations/washington/snohomish",
  "/es/sucursales/washington/redmond": "/locations/washington/redmond",
  "/es/sucursales/washington/bellevue": "/locations/washington/bellevue",
  "/es/sucursales/washington/bellevue/crossroads": "/locations/washington/bellevue/crossroads",
  "/es/sucursales/washington/bellevue/bel-red-road": "/locations/washington/bellevue/bel-red-road",
  "/es/sucursales/washington/bellevue/eastgate": "/locations/washington/bellevue/eastgate",
  "/es/sucursales/washington/bellevue/downtown": "/locations/washington/bellevue/downtown",
  "/es/sucursales/washington/bellevue/west": "/locations/washington/bellevue/west",
  "/es/sucursales/washington/woodinville": "/locations/washington/woodinville",
  "/es/sucursales/washington/bothell": "/locations/washington/bothell",
  "/es/sucursales/washington/kirkland/juanita": "/locations/washington/kirkland/juanita",
  "/es/sucursales/washington/kirkland/kirkland-ave": "/locations/washington/kirkland/kirkland-ave",
  "/es/sucursales/washington/everett/bothell-everett-way": "/locations/washington/everett/bothell-everett-way",
  "/es/sucursales/washington/bothell/canyon-park-place": "/locations/washington/bothell/canyon-park-place",
  "/es/sucursales/washington/marysville": "/locations/washington/marysville",
  "/es/sucursales/washington/everett/evergreen-way": "/locations/washington/everett/evergreen-way",
  "/es/sucursales/washington/seattle/wedgwood": "/locations/washington/seattle/wedgwood",
  "/es/sucursales/washington/omak": "/locations/washington/omak",
  "/es/sucursales/washington/seattle/rainier-beach": "/locations/washington/seattle/rainier-beach",
  "/es/sucursales/washington/kent": "/locations/washington/kent",
  "/es/sucursales/washington/seattle/jackson-park": "/locations/washington/seattle/jackson-park",
  "/es/sucursales/washington/lynnwood": "/locations/washington/lynnwood",
  "/es/sucursales/washington/seattle/columbia-city": "/locations/washington/seattle/columbia-city",
  "/es/sucursales/washington/seattle/u-district": "/locations/washington/seattle/u-district",
  "/es/sucursales/washington/seattle/beacon-hill": "/locations/washington/seattle/beacon-hill",
  "/es/sucursales/washington/seattle/international-district": "/locations/washington/seattle/international-district",
  "/es/sucursales/washington/seattle/downtown": "/locations/washington/seattle/downtown",
  "/es/sucursales/washington/auburn": "/locations/washington/auburn",
  "/es/sucursales/washington/seattle/crown-hill": "/locations/washington/seattle/crown-hill",
  "/es/sucursales/washington/edmonds": "/locations/washington/edmonds",
  "/es/sucursales/washington/seattle/ballard": "/locations/washington/seattle/ballard",
  "/es/sucursales/washington/seattle/magnolia": "/locations/washington/seattle/magnolia",
  "/es/sucursales/washington/seattle/california-ave-dakota": "/locations/washington/seattle/california-ave-dakota",
  "/es/sucursales/washington/federal-way": "/locations/washington/federal-way",
  "/es/sucursales/washington/yakima": "/locations/washington/yakima",
  "/es/sucursales/washington/kennewick": "/locations/washington/kennewick",
  "/es/sucursales/washington/moses-lake": "/locations/washington/moses-lake",
  "/es/sucursales/washington/stanwood": "/locations/washington/stanwood",
  "/es/sucursales/washington/bainbridge-island": "/locations/washington/bainbridge-island",
  "/es/sucursales/washington/puyallup": "/locations/washington/puyallup",
  "/es/sucursales/washington/mount-vernon": "/locations/washington/mount-vernon",
  "/es/sucursales/washington/poulsbo": "/locations/washington/poulsbo",
  "/es/sucursales/washington/bremerton": "/locations/washington/bremerton",
  "/es/sucursales/washington/burlington": "/locations/washington/burlington",
  "/es/sucursales/washington/university-place": "/locations/washington/university-place",
  "/es/sucursales/washington/lakewood": "/locations/washington/lakewood",
  "/es/sucursales/washington/la-conner": "/locations/washington/la-conner",
  "/es/sucursales/washington/oak-harbor": "/locations/washington/oak-harbor",
  "/es/sucursales/washington/odessa": "/locations/washington/odessa",
  "/es/sucursales/washington/anacortes": "/locations/washington/anacortes",
  "/es/sucursales/washington/bellingham/lakeway-drive": "/locations/washington/bellingham/lakeway-drive",
  "/es/sucursales/washington/bellingham/barkley-blvd": "/locations/washington/bellingham/barkley-blvd",
  "/es/sucursales/washington/bellingham/cornwall-ave": "/locations/washington/bellingham/cornwall-ave",
  "/es/sucursales/washington/bellingham/bakerview": "/locations/washington/bellingham/bakerview",
  "/es/sucursales/washington/lacey": "/locations/washington/lacey",
  "/es/sucursales/washington/republic": "/locations/washington/republic",
  "/es/sucursales/washington/sequim": "/locations/washington/sequim",
  "/es/sucursales/washington/ferndale": "/locations/washington/ferndale",
  "/es/sucursales/washington/lynden": "/locations/washington/lynden",
  "/es/sucursales/washington/olympia": "/locations/washington/olympia",
  "/es/sucursales/washington/eastsound": "/locations/washington/eastsound",
  "/es/sucursales/washington/blaine": "/locations/washington/blaine",
  "/es/sucursales/washington/centralia": "/locations/washington/centralia",
  "/es/sucursales/washington/colville": "/locations/washington/colville",
  "/es/sucursales/washington/chewelah": "/locations/washington/chewelah",
  "/es/sucursales/washington/deer-park": "/locations/washington/deer-park",
  "/es/sucursales/washington/spokane": "/locations/washington/spokane",
  "/es/sucursales/washington/walla-walla": "/locations/washington/walla-walla",
  "/es/sucursales/washington/dayton": "/locations/washington/dayton",
  "/es/sucursales/washington/colfax": "/locations/washington/colfax",
  "/es/sucursales/washington/vancouver": "/locations/washington/vancouver",
  "/es/sucursales/washington/newport": "/locations/washington/newport",
  "/es/sucursales/washington/pullman": "/locations/washington/pullman",
  "/es/sucursales/washington/clarkston": "/locations/washington/clarkston",
  "/es/sucursales/oregon": "/locations/oregon",
  "/es/sucursales/oregon/prineville": "/locations/oregon/prineville",
  "/es/sucursales/oregon/bend/purcell-blvd": "/locations/oregon/bend/purcell-blvd",
  "/es/sucursales/oregon/bend/bluff-drive": "/locations/oregon/bend/bluff-drive",
  "/es/sucursales/oregon/redmond": "/locations/oregon/redmond",
  "/es/sucursales/oregon/la-pine": "/locations/oregon/la-pine",
  "/es/sucursales/oregon/sisters": "/locations/oregon/sisters",
  "/es/sucursales/oregon/madras": "/locations/oregon/madras",
  "/es/sucursales/oregon/lakeview": "/locations/oregon/lakeview",
  "/es/sucursales/oregon/klamath-falls/campus": "/locations/oregon/klamath-falls/campus",
  "/es/sucursales/oregon/klamath-falls/sixth-street": "/locations/oregon/klamath-falls/sixth-street",
  "/es/sucursales/oregon/klamath-falls/main-street": "/locations/oregon/klamath-falls/main-street",
  "/es/sucursales/oregon/eugene/coburg-road": "/locations/oregon/eugene/coburg-road",
  "/es/sucursales/oregon/eugene/downtown": "/locations/oregon/eugene/downtown",
  "/es/sucursales/oregon/the-dalles": "/locations/oregon/the-dalles",
  "/es/sucursales/oregon/albany": "/locations/oregon/albany",
  "/es/sucursales/oregon/hood-river": "/locations/oregon/hood-river",
  "/es/sucursales/oregon/corvallis": "/locations/oregon/corvallis",
  "/es/sucursales/oregon/salem/broadway-street": "/locations/oregon/salem/broadway-street",
  "/es/sucursales/oregon/salem/wallace-road": "/locations/oregon/salem/wallace-road",
  "/es/sucursales/oregon/woodburn": "/locations/oregon/woodburn",
  "/es/sucursales/oregon/white-city": "/locations/oregon/white-city",
  "/es/sucursales/oregon/gresham": "/locations/oregon/gresham",
  "/es/sucursales/oregon/wilsonville": "/locations/oregon/wilsonville",
  "/es/sucursales/oregon/monmouth": "/locations/oregon/monmouth",
  "/es/sucursales/oregon/lake-oswego": "/locations/oregon/lake-oswego",
  "/es/sucursales/oregon/hermiston": "/locations/oregon/hermiston",
  "/es/sucursales/oregon/medford/biddle-road": "/locations/oregon/medford/biddle-road",
  "/es/sucursales/oregon/ashland": "/locations/oregon/ashland",
  "/es/sucursales/oregon/tigard": "/locations/oregon/tigard",
  "/es/sucursales/oregon/dallas": "/locations/oregon/dallas",
  "/es/sucursales/oregon/portland/downtown": "/locations/oregon/portland/downtown",
  "/es/sucursales/oregon/portland/raleigh-hills": "/locations/oregon/portland/raleigh-hills",
  "/es/sucursales/oregon/la-grande": "/locations/oregon/la-grande",
  "/es/sucursales/oregon/grants-pass/north-valley": "/locations/oregon/grants-pass/north-valley",
  "/es/sucursales/oregon/grants-pass/city-centre": "/locations/oregon/grants-pass/city-centre",
  "/es/sucursales/oregon/hillsboro": "/locations/oregon/hillsboro",
  "/es/sucursales/oregon/grants-pass/south-river": "/locations/oregon/grants-pass/south-river",
  "/es/sucursales/oregon/florence": "/locations/oregon/florence",
  "/es/sucursales/oregon/ontario": "/locations/oregon/ontario",
  "/es/sucursales/oregon/waldport": "/locations/oregon/waldport",
  "/es/sucursales/oregon/milton-freewater": "/locations/oregon/milton-freewater",
  "/es/sucursales/oregon/newport": "/locations/oregon/newport",
  "/es/sucursales/oregon/lincoln-city": "/locations/oregon/lincoln-city",
  "/es/sucursales/oregon/cave-junction": "/locations/oregon/cave-junction",
  "/es/sucursales/idaho": "/locations/idaho",
  "/es/sucursales/idaho/ketchum": "/locations/idaho/ketchum",
  "/es/sucursales/idaho/hailey": "/locations/idaho/hailey",
  "/es/sucursales/idaho/gooding": "/locations/idaho/gooding",
  "/es/sucursales/idaho/boise/west-idaho-street": "/locations/idaho/boise/west-idaho-street",
  "/es/sucursales/idaho/boise/federal-way": "/locations/idaho/boise/federal-way",
  "/es/sucursales/idaho/boise/cole-road": "/locations/idaho/boise/cole-road",
  "/es/sucursales/idaho/boise/towne-square": "/locations/idaho/boise/towne-square",
  "/es/sucursales/idaho/boise/fairview-ave": "/locations/idaho/boise/fairview-ave",
  "/es/sucursales/idaho/eagle": "/locations/idaho/eagle",
  "/es/sucursales/idaho/salmon": "/locations/idaho/salmon",
  "/es/sucursales/idaho/meridian": "/locations/idaho/meridian",
  "/es/sucursales/idaho/mccall": "/locations/idaho/mccall",
  "/es/sucursales/idaho/jerome": "/locations/idaho/jerome",
  "/es/sucursales/idaho/nampa/11th-ave": "/locations/idaho/nampa/11th-ave",
  "/es/sucursales/idaho/nampa/marketplace": "/locations/idaho/nampa/marketplace",
  "/es/sucursales/idaho/caldwell": "/locations/idaho/caldwell",
  "/es/sucursales/idaho/twin-falls": "/locations/idaho/twin-falls",
  "/es/sucursales/idaho/payette": "/locations/idaho/payette",
  "/es/sucursales/idaho/blackfoot": "/locations/idaho/blackfoot",
  "/es/sucursales/idaho/pocatello": "/locations/idaho/pocatello",
  "/es/sucursales/idaho/idaho-falls": "/locations/idaho/idaho-falls",
  "/es/sucursales/idaho/rexburg": "/locations/idaho/rexburg",
  "/es/sucursales/idaho/silver-valley": "/locations/idaho/silver-valley",
  "/es/sucursales/idaho/sandpoint": "/locations/idaho/sandpoint",
  "/es/sucursales/nevada": "/locations/nevada",
  "/es/sucursales/nevada/ely": "/locations/nevada/ely",
  "/es/sucursales/nevada/fallon": "/locations/nevada/fallon",
  "/es/sucursales/nevada/elko": "/locations/nevada/elko",
  "/es/sucursales/nevada/fernley": "/locations/nevada/fernley",
  "/es/sucursales/nevada/north-las-vegas": "/locations/nevada/north-las-vegas",
  "/es/sucursales/nevada/mesquites": "/locations/nevada/mesquites",
  "/es/sucursales/nevada/overton": "/locations/nevada/overton",
  "/es/sucursales/nevada/las-vegas/summerlin": "/locations/nevada/las-vegas/summerlin",
  "/es/sucursales/nevada/las-vegas/rhodes-ranch": "/locations/nevada/las-vegas/rhodes-ranch",
  "/es/sucursales/nevada/henderson": "/locations/nevada/henderson",
  "/es/sucursales/utah": "/locations/utah",
  "/es/sucursales/utah/price": "/locations/utah/price",
  "/es/sucursales/utah/draper": "/locations/utah/draper",
  "/es/sucursales/utah/murray": "/locations/utah/murray",
  "/es/sucursales/utah/salt-lake-city/cottonwood": "/locations/utah/salt-lake-city/cottonwood",
  "/es/sucursales/utah/salt-lake-city/sugarhouse": "/locations/utah/salt-lake-city/sugarhouse",
  "/es/sucursales/utah/salt-lake-city/foothill-drive": "/locations/utah/salt-lake-city/foothill-drive",
  "/es/sucursales/utah/salt-lake-city/plaza": "/locations/utah/salt-lake-city/plaza",
  "/es/sucursales/utah/layton": "/locations/utah/layton",
  "/es/sucursales/utah/logan": "/locations/utah/logan",
  "/es/sucursales/arizona": "/locations/arizona",
  "/es/sucursales/arizona/payson": "/locations/arizona/payson",
  "/es/sucursales/arizona/fountain-hills": "/locations/arizona/fountain-hills",
  "/es/sucursales/arizona/globe": "/locations/arizona/globe",
  "/es/sucursales/arizona/mesa/mckellips-road": "/locations/arizona/mesa/mckellips-road",
  "/es/sucursales/arizona/mesa/superstition-springs": "/locations/arizona/mesa/superstition-springs",
  "/es/sucursales/arizona/phoenix/paradise-valley": "/locations/arizona/phoenix/paradise-valley",
  "/es/sucursales/arizona/scottsdale": "/locations/arizona/scottsdale",
  "/es/sucursales/arizona/show-low": "/locations/arizona/show-low",
  "/es/sucursales/arizona/gilbert": "/locations/arizona/gilbert",
  "/es/sucursales/arizona/phoenix/camelback-road": "/locations/arizona/phoenix/camelback-road",
  "/es/sucursales/arizona/glendale": "/locations/arizona/glendale",
  "/es/sucursales/arizona/sun-city": "/locations/arizona/sun-city",
  "/es/sucursales/arizona/sun-city-west": "/locations/arizona/sun-city-west",
  "/es/sucursales/arizona/prescott-valley": "/locations/arizona/prescott-valley",
  "/es/sucursales/arizona/goodyear": "/locations/arizona/goodyear",
  "/es/sucursales/arizona/safford": "/locations/arizona/safford",
  "/es/sucursales/arizona/tucson/oracle-and-magee": "/locations/arizona/tucson/oracle-and-magee",
  "/es/sucursales/arizona/tucson/sunrise-drive": "/locations/arizona/tucson/sunrise-drive",
  "/es/sucursales/arizona/tucson/flowing-wells": "/locations/arizona/tucson/flowing-wells",
  "/es/sucursales/arizona/tucson/grant-and-craycroft": "/locations/arizona/tucson/grant-and-craycroft",
  "/es/sucursales/arizona/tucson/4910-broadway-blvd": "/locations/arizona/tucson/4910-broadway-blvd",
  "/es/sucursales/arizona/tucson/camino-seco": "/locations/arizona/tucson/camino-seco",
  "/es/sucursales/arizona/tucson/campbell": "/locations/arizona/tucson/campbell",
  "/es/sucursales/arizona/tucson/south-tucson": "/locations/arizona/tucson/south-tucson",
  "/es/sucursales/arizona/green-valley": "/locations/arizona/green-valley",
  "/es/sucursales/arizona/benson": "/locations/arizona/benson",
  "/es/sucursales/arizona/nogales": "/locations/arizona/nogales",
  "/es/sucursales/arizona/bisbee": "/locations/arizona/bisbee",
  "/es/sucursales/arizona/douglas": "/locations/arizona/douglas",
  "/es/sucursales/arizona/yuma/fortuna": "/locations/arizona/yuma/fortuna",
  "/es/sucursales/arizona/yuma/4th-ave": "/locations/arizona/yuma/4th-ave",
  "/es/sucursales/arizona/surprise": "/locations/arizona/surprise",
  "/es/sucursales/nuevo-mexico": "/locations/new-mexico",
  "/es/sucursales/nuevo-mexico/albuquerque/eubank-blvd": "/locations/new-mexico/albuquerque/eubank-blvd",
  "/es/sucursales/nuevo-mexico/albuquerque/osuna-road": "/locations/new-mexico/albuquerque/osuna-road",
  "/es/sucursales/nuevo-mexico/albuquerque/west-albuquerque": "/locations/new-mexico/albuquerque/west-albuquerque",
  "/es/sucursales/nuevo-mexico/socorro": "/locations/new-mexico/socorro",
  "/es/sucursales/nuevo-mexico/rio-rancho": "/locations/new-mexico/rio-rancho",
  "/es/sucursales/nuevo-mexico/capitan": "/locations/new-mexico/capitan",
  "/es/sucursales/nuevo-mexico/santa-fe/st-michaels-drive": "/locations/new-mexico/santa-fe/st-michaels-drive",
  "/es/sucursales/nuevo-mexico/santa-fe/downtown": "/locations/new-mexico/santa-fe/downtown",
  "/es/sucursales/nuevo-mexico/ruidoso": "/locations/new-mexico/ruidoso",
  "/es/sucursales/nuevo-mexico/espanola": "/locations/new-mexico/espanola",
  "/es/sucursales/nuevo-mexico/roswell/north-main-street": "/locations/new-mexico/roswell/north-main-street",
  "/es/sucursales/nuevo-mexico/roswell/pennsylvania-ave": "/locations/new-mexico/roswell/pennsylvania-ave",
  "/es/sucursales/nuevo-mexico/roswell/south-main-street": "/locations/new-mexico/roswell/south-main-street",
  "/es/sucursales/nuevo-mexico/alamogordo": "/locations/new-mexico/alamogordo",
  "/es/sucursales/nuevo-mexico/angel-fire": "/locations/new-mexico/angel-fire",
  "/es/sucursales/nuevo-mexico/artesia": "/locations/new-mexico/artesia",
  "/es/sucursales/nuevo-mexico/clovis": "/locations/new-mexico/clovis",
  "/es/sucursales/nuevo-mexico/las-cruces/amador-ave": "/locations/new-mexico/las-cruces/amador-ave",
  "/es/sucursales/nuevo-mexico/chama": "/locations/new-mexico/chama",
  "/es/sucursales/nuevo-mexico/gallup": "/locations/new-mexico/gallup",
  "/es/sucursales/nuevo-mexico/raton": "/locations/new-mexico/raton",
  "/es/sucursales/nuevo-mexico/silver-city/hudson-street": "/locations/new-mexico/silver-city/hudson-street",
  "/es/sucursales/nuevo-mexico/silver-city/11th-street": "/locations/new-mexico/silver-city/11th-street",
  "/es/sucursales/nuevo-mexico/hobbs": "/locations/new-mexico/hobbs",
  "/es/sucursales/nuevo-mexico/farmington": "/locations/new-mexico/farmington",
  "/es/sucursales/texas": "/locations/texas",
  "/es/sucursales/texas/denton": "/locations/texas/denton",
  "/es/sucursales/texas/dallas/lovers-lane": "/locations/texas/dallas/lovers-lane",
  "/es/sucursales/texas/dallas/midtown": "/locations/texas/dallas/midtown",
  "/es/sucursales/texas/plano": "/locations/texas/plano",
  "/es/sucursales/arizona/mesa": "/locations/arizona/mesa",
  "/es/sucursales/arizona/phoenix": "/locations/arizona/phoenix",
  "/es/sucursales/arizona/tucson": "/locations/arizona/tucson",
  "/es/sucursales/arizona/yuma": "/locations/arizona/yuma",
  "/es/sucursales/idaho/boise": "/locations/idaho/boise",
  "/es/sucursales/idaho/nampa": "/locations/idaho/nampa",
  "/es/sucursales/nuevo-mexico/albuquerque": "/locations/new-mexico/albuquerque",
  "/es/sucursales/nuevo-mexico/las-cruces": "/locations/new-mexico/las-cruces",
  "/es/sucursales/nuevo-mexico/roswell": "/locations/new-mexico/roswell",
  "/es/sucursales/nuevo-mexico/santa-fe": "/locations/new-mexico/santa-fe",
  "/es/sucursales/nuevo-mexico/silver-city": "/locations/new-mexico/silver-city",
  "/es/sucursales/oregon/bend": "/locations/oregon/bend",
  "/es/sucursales/oregon/eugene": "/locations/oregon/eugene",
  "/es/sucursales/oregon/grants-pass": "/locations/oregon/grants-pass",
  "/es/sucursales/oregon/klamath-falls": "/locations/oregon/klamath-falls",
  "/es/sucursales/oregon/medford": "/locations/oregon/medford",
  "/es/sucursales/oregon/portland": "/locations/oregon/portland",
  "/es/sucursales/oregon/salem": "/locations/oregon/salem",
  "/es/sucursales/texas/dallas": "/locations/texas/dallas",
  "/es/sucursales/utah/salt-lake-city": "/locations/utah/salt-lake-city",
  "/es/sucursales/washington/bellingham": "/locations/washington/bellingham",
  "/es/sucursales/washington/everett": "/locations/washington/everett",
  "/es/sucursales/washington/kirkland": "/locations/washington/kirkland",
  "/es/sucursales/washington/seattle": "/locations/washington/seattle",
  "/es/sucursales/nevada/las-vegas": "/locations/nevada/las-vegas",
  "/es/sucursales/texas/austin": "/locations/texas/austin",
  "/es/sucursales/nuevo-mexico/albuquerque/albuquerque-plaza": "/locations/new-mexico/albuquerque/albuquerque-plaza",
  "/es/sucursales/california/santa-rosa": "/locations/california/santa-rosa",
  "/es/sucursales/california/beverly-hills": "/locations/california/beverly-hills",
  "/es/sucursales/california/los-altos": "/locations/california/los-altos",
  "/es/sucursales/california/el-segundo": "/locations/california/el-segundo",
  "/es/sucursales/california/encino": "/locations/california/encino",
  "/es/sucursales/california/long-beach": "/locations/california/long-beach",
  "/es/sucursales/california/pasadena": "/locations/california/pasadena",
  "/es/sucursales/california/san-jose": "/locations/california/san-jose",
  "/es/sucursales/california/san-rafael": "/locations/california/san-rafael",
  "/es/sucursales/california/burbank-toluca-lake": "/locations/california/burbank-toluca-lake",
  "/es/sucursales/washington/bellevue/central": "/locations/washington/bellevue/central",
  "/es/sucursales/california": "/locations/california",
  "/es/tu-decides": "/blog",
  "/es/tu-decides/ahorrar-para-la-universidad": "/blog/family-finance/saving-for-college",
  "/es/tu-decides/que-hacer-con-el-retorno-de-impuestos-anual": "/blog/consumer-tips/what-to-do-with-tax-refund",
  "/es/tu-decides/ensenar-a-los-hijos-sobre-el-dinero": "/blog/family-finance/ideas-financial-literacy-kids",
  "/es/tu-decides/como-mantener-finanzas-saludables": "/blog/consumer-tips/financial-fitness",
  "/es/tu-decides/como-aumentar-su-puntaje-crediticio": "/blog/building-credit/how-to-boost-your-credit-score",
  "/es/tu-decides/como-lograr-este-objetivo-de-ahorro": "/blog/consumer-tips/boost-your-savings",
  "/es/tu-decides/termina-anualmente-con-buena-salud-financiera": "/blog/consumer-tips/year-end-good-habits",
  "/es/tu-decides/consejos-para-recortar-gastos-en-casa": "/blog/consumer-tips/cutting-costs-around-the-house",
  "/es/tu-decides/compra-tu-casa": "/blog/home-ownership/best-age-to-buy-a-house",
  "/es/tu-decides/costos-de-cierre": "/blog/home-ownership/closing-costs",
  "/es/tu-decides/vender-su-casa": "/blog/home-ownership/selling-your-home",
  "/es/tu-decides/lista-de-verificacion-de-casa-nueva": "/blog/home-ownership/new-home-checklist",
  "/es/tu-decides/prepararse-para-las-incertidumbres-economicas":
    "/blog/banking-101/prepare-for-economic-uncertainties",
  "/es/tu-decides/mejores-consejos-para-presupuestar": "/blog/banking-101/top-budgeting-tips",
  "/es/work-perks": "/workplace-banking",
  "/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start":
    "/personal-banking/home-loans/smart-start-down-payment-assistance",
  "/es/sucursales/nevada/las-vegas/aliante": "/locations/nevada/las-vegas/aliante",
  "/es/sucursales/nevada/mesquite": "/locations/nevada/mesquite",
  "/es/sucursales/nevada/las-vegas/park-place": "/locations/nevada/las-vegas/park-place",
  "/es/sucursales/oregon/portland/burnside": "/locations/oregon/portland/burnside",
  "/es/sucursales/washington/bothell/183rd-street": "/locations/washington/bothell/183rd-street",
  "/es/banca-personal/cuenta-nuevo-comienzo": "/personal-banking/fresh-start-second-chance-account",
  "/es/banca-personal/prestamos-hipotecarios/prestamos-para-lotes-y-terrenos":
    "/personal-banking/home-loans/land-loans",
  "/es/banca-de-negocios": "/business-banking/small-business",
  "/es/banca-personal/interest-rewards-checking": "/personal-banking/checking-account/interest-rewards-checking",
  "/es/banca-personal/preguntas-frecuentes-sobre-los-rewards-de-cuentas-de-cheques":
    "/personal-banking/rewards-checking-faq",
  "/es/tu-decides/como-escribir-un-cheque": "/blog/consumer-tips/how-to-write-a-check"
};

module.exports = {
  spanishToEnglish,
  englishToSpanish
};
