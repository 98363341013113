import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";

const LanguageContext = React.createContext({ isSpanish: false });

function LanguageProvider({ children }) {
  const [isSpanish, setIsSpanish] = useState(false);

  let path = useLocation().pathname;
  // Remove trailing slash (/) from the URL if it exists
  path = path.replace(/\/$/, "");
  // Now explicitly add a trailing slash to act as a word break for ".../es..."
  path = path + "/";

  useEffect(() => {
    if (path.includes("/es/")) {
      setIsSpanish(true);
      //console.log("Language Context is Spanish");
    } else {
      setIsSpanish(false);
      //console.log("Language Context is English");
    }
  }, []);

  return <LanguageContext.Provider value={isSpanish}>{children}</LanguageContext.Provider>;
}

function useLanguageContext() {
  const context = React.useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguageContext must be used within a LanguageProvider");
  }
  return context;
}

export { LanguageProvider, useLanguageContext };
