/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { usePageUtagDataContext } from "../../contexts/page-utag-data-context";

function setUtagData(pageData) {
  Object.assign(window.utag.data, {
    page_section: pageData.page_section,
    page_category: pageData.page_category,
    page_name: pageData.page_name,
    tealium_event: pageData.tealium_event,
    site_language: pageData.site_language
  });
}

const UtagView = () => {
  // unfortunately the pageData is not defined until way after page load so...
  const pageDataContext = usePageUtagDataContext();
  const [pageData, setPageData] = useState({});
  const [isUtagJsLoaded, setIsUtagJsLoaded] = useState(false);

  useEffect(() => {
    if (pageDataContext === null || pageDataContext === undefined || pageDataContext.tealium_event === null) {
      return null;
    }
    setPageData({ ...pageDataContext });
  }, [pageDataContext]);

  useEffect(() => {
    if (!isUtagJsLoaded) return null;
    setUtagData(pageData);
      let script = document.createElement("script");
      const scriptText = document.createTextNode(
        `window.utag?.view({ "page_section": "${pageData.page_section}", "page_category": "${pageData.page_category}", "page_name": "${pageData.page_name}", "site_language": "${pageData.site_language}"});`
      );
      script.id = "tealium-utag-view-gatsby";
      script.appendChild(scriptText);
      document.body.appendChild(script);

    // cleanup on page tear down
    return (el) => {
      document.body.removeChild(script);
    };
  }, [pageData, isUtagJsLoaded]);

  useEffect(() => {
    function handleUtagScriptLoad() {
      if (typeof window?.utag?.view === "function") {
        setIsUtagJsLoaded(true);
      }
    }
    const utagScript = document.getElementById("utag-script");
    utagScript.addEventListener("load", handleUtagScriptLoad);
    return () => utagScript.removeEventListener("load", handleUtagScriptLoad);
  }, []);

  return <></>;
};

export default UtagView;
