import React, { useEffect } from "react";

const TealiumUniversalTag = () => {
  /* Currently there are only two environments defined in the pipeline, 
    "development" and "production", which we need to map to "dev" and "prod" respectively.
    TODO: update as needed with pipeline environments for demo and test. */
  const envMap = {
    default: "dev",
    development: "dev",
    demo: "dev",
    test: "dev",
    production: "prod"
  };

  // const env =
  //   process.env.GATSBY_TEALIUM_ENV && process.env.GATSBY_TEALIUM_ENV !== undefined
  //     ? envMap[process.env.GATSBY_TEALIUM_ENV]
  //     : envMap.default;

  useEffect(() => {
    const env = window.location.hostname === "www.wafdbank.com" ? envMap.production : envMap.default;
    let script = document.getElementById("tealium-utag-script-gatsby");
    if (!script) {
      script = document.createElement("script");
      script.innerHTML = `(function (a, b, c, d) {
        a = "https://tags.tiqcdn.com/utag/wafd/main/${env}/utag.js";
        b = document;
        c = "script";
        d = b.createElement(c);
        d.src = a;
        d.type = "text/java" + c;
        d.async = true;
        d.id = "utag-script"
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })()`;
      script.id = "tealium-utag-script-gatsby";
      document.body.appendChild(script);
    }

    // cleanup on page tear down
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <></>;
};

export default TealiumUniversalTag;
