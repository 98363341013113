/* eslint-disable no-console */
const allowlist = [
  "wafd",
  "washingtonfed",
  "WAFDBank",
  "mailto:",
  "tel:",
  "javascript",
  "vimeo",
  "maps.google.com",
  "timetrade",
  "washington-federal",
  "washingtionfederal",
  "wafedweb",
  "washfed",
  "www.ezcardinfo.com",
  "www.ezbusinesscardmanagement.com",
  "myaccountaccess.com",
  "ezapprove.fnis.com",
  "idp.elliemae.com",
  "pay.google.com",
  "www.samsung.com",
  "www.apple.com",
  "zoom.us"
];

const isLinkUrlExternal = function (url) {
  if (!url) {
    console.warn("isLinkUrlExternal(): link url is not defined");
    return null;
  }

  // if the url is a relative path (first char is "/")
  if (url.indexOf("/") === 0) {
    // console.log(`link url "${url}" is a relative path`);
    return false;
  }

  let whiteListed = false;
  // if the url contains the actual host (wafdbank.com or localhost) then it is NOT an external link
  if (typeof window !== "undefined" && url.indexOf(window.location.host) >= 0) {
    return false;
  } else {
    // console.log(`link url "${url}" is external`);
    allowlist.forEach((text) => {
      if (url.indexOf(text) >= 0) {
        // console.log(`link url "${url}" is whiteListed`);
        whiteListed = true;
        return false; // does nothing
      }
    });
  }

  // not whiteListed
  return !whiteListed;
};

export default isLinkUrlExternal;
