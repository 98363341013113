const footerLinksEnglish = [
    {
      title: "Personal Banking",
      id: "sml-personal-banking",
      url: "/personal-banking",
      links: [
        {
          id: "sml-pb-checking-account",
          text: "Checking",
          url: "/personal-banking/checking-account"
        },
        {
          id: "sml-pb-free-checking",
          text: "Free Checking",
          url: "/personal-banking/free-checking-account"
        },
        {
          id: "sml-pb-green-checking",
          text: "Rewards and Premium Rewards Checking",
          url: "/personal-banking/checking-account/rewards-checking"
        },
        {
          id: "sml-pb-stellar-plus-checking",
          text: "Interest Checking",
          url: "/personal-banking/checking-account/interest-rewards-checking"
        },
        {
          id: "sml-pb-private-banking",
          text: "Select Banking",
          url: "/personal-banking/private-banking-services"
        },
        {
          id: "sml-pb-savings-cd-mm",
          text: "Savings & Money Markets",
          url: "/personal-banking/savings-account"
        },
        {
          id: "sml-pb-cds",
          text: "Certificates of Deposit (CDs)",
          url: "/personal-banking/savings-account/certificate-of-deposit"
        },
        {
          id: "sml-pb-ira-accounts",
          text: "Retirement",
          url: "/personal-banking/retirement-ira-accounts"
        },
        {
          id: "sml-pb-credit-cards",
          text: "Credit Cards",
          url: "/personal-banking/credit-cards"
        },
        {
          id: "sml-pb-debit-cards",
          text: "Debit Cards",
          url: "/personal-banking/debit-cards"
        },
        {
          id: "sml-pb-online-banking",
          text: "Mobile & Online Banking",
          url: "/personal-banking/online-banking"
        },
        {
          id: "sml-pb-interest-rates",
          text: "Personal Rates",
          url: "/personal-banking/interest-rates"
        },
        {
          id: "sml-pb-account-fees",
          text: "Fees",
          url: "/personal-banking/account-fees"
        },
        {
          id: "sml-pb-account-details",
          text: "Disclosures",
          url: "/customer-service/account-details"
        },
        {
          id: "sml-bb-personal-account-login",
          text: "Personal Account Sign In",
          url: "/personal-banking/login"
        }
      ]
    },
    {
      title: "Business Banking",
      id: "sml-business-banking",
      url: "/business-banking/small-business",
      links: [
        {
          id: "sml-bb-small-business",
          text: "Small Business Banking",
          url: "/business-banking/small-business"
        },
        {
          id: "sml-bb-checking-account",
          text: "Business Checking",
          url: "/business-banking/business-checking-account"
        },
        {
          id: "sml-bb-savings-account",
          text: "Business Savings",
          url: "/business-banking/business-savings-account"
        },
        {
          id: "sml-bb-cd-money-market-rates",
          text: "Business Rates",
          url: "/business-banking/business-cd-money-market-rates"
        },
        {
          id: "sml-bb-credit-cards",
          text: "Small Business Credit Cards",
          url: "/business-banking/small-business-credit-card"
        },
        {
          id: "sml-bb-line-of-credit",
          text: "Credit Solutions",
          url: "/business-banking/business-line-of-credit"
        },
        {
          id: "sml-bb-online-banking",
          text: "Cashflow Solutions & Online Banking",
          url: "/business-banking/online-banking"
        },
        {
          id: "sml-bb-banking-officer",
          text: "Find A Banking Officer",
          url: "/business-banking/near-me"
        },
        {
          id: "sml-bb-treasury-express-login",
          text: "WAFD Treasury Express Sign In",
          url: "/business-banking/login"
        }
      ]
    },
    {
      title: "Commercial Banking",
      id: "sml-commercial-banking",
      url: "/commercial-banking",
      links: [
        {
          id: "sml-cb-commercial-lending",
          text: "Commercial Lending",
          url: "/commercial-banking/commercial-lending"
        },
        {
          id: "sml-cb-commercial-line-of-credit",
          text: "Commercial Line of Credit",
          url: "/commercial-banking/commercial-line-of-credit"
        },
        {
          id: "sml-cb-business-equipment-financing",
          text: "Equipment Finance",
          url: "/commercial-banking/business-equipment-financing"
        },
        {
          id: "sml-cb-acquisition-financing",
          text: "Acquisition Financing",
          url: "/commercial-banking/acquisition-financing"
        },
        {
          id: "sml-cb-real-estate-financing",
          text: "Owner-Occupied Real Estate Financing",
          url: "/commercial-banking/owner-occupied-real-estate-financing"
        },
        {
          id: "sml-cb-term-loans",
          text: "Commercial Term Loans",
          url: "/commercial-banking/commercial-term-loans"
        },
        {
          id: "sml-cb-real-estate-loans",
          text: "Commercial Real Estate",
          url: "/commercial-banking/commercial-real-estate-loans"
        },
        {
          id: "sml-cb-multifamily-and-self-storage-loans",
          text: "Community Real Estate Lending",
          url: "/commercial-banking/multifamily-self-storage-loans"
        },
        {
          id: "sml-cb-real-estate-products-services",
          text: "Commercial Real Estate Products and Services",
          url: "/commercial-banking/commercial-real-estate-products-and-services"
        },
        {
          id: "sml-cb-treasury-management",
          text: "Treasury Management",
          url: "/commercial-banking/treasury-management-services"
        },
        {
          id: "sml-cb-commercial-banker",
          text: "Find a Commercial Banker",
          url: "/commercial-banking/commercial-banker-near-me"
        },
        {
          id: "sml-cb-treasury-prime-login",
          text: "WAFD Treasury Prime Sign In",
          url: "/commercial-banking/login"
        }
      ]
    },
    {
      title: "Home Loans",
      id: "sml-home-loans",
      url: "/personal-banking/home-loans",
      links: [
        {
          id: "sml-hl-home-buyer-loans",
          text: "First Time Home Buyer",
          url: "/personal-banking/home-loans/first-time-home-buyer-loans"
        },
        {
          id: "sml-hl-home-loans",
          text: "Home Loans",
          url: "/personal-banking/home-loans/home-mortgage"
        },
        {
          id: "sml-hl-refinance",
          text: "Refinancing",
          url: "/personal-banking/home-loans/mortgage-refinance"
        },
        {
          id: "sml-hl-home-improvement-loans",
          text: "Remodeling",
          url: "/personal-banking/home-loans/home-improvement-loans"
        },
        {
          id: "sml-hl-construction-loans",
          text: "Construction",
          url: "/personal-banking/home-loans/construction-loans"
        },
        {
          id: "sml-hl-land-loans",
          text: "Lot Loans",
          url: "/personal-banking/home-loans/land-loans"
        },
        {
          id: "sml-hl-heloc",
          text: "Home Equity",
          url: "/personal-banking/home-loans/using-home-equity-heloc-vs-home-equity-loan"
        },
        {
          id: "sml-hl-wholesale-mortgage-lending",
          text: "Wholesale Lending",
          url: "/personal-banking/home-loans/wholesale-mortgage-lending"
        },
        {
          id: "sml-hl-apply-online",
          text: "Contact a Loan Officer",
          url: "/personal-banking/contact-loan-officer"
        },
        {
          id: "sml-hl-homeowner-assistance",
          text: "Homeowner Assistance",
          url: "/personal-banking/home-loans/homeowner-assistance"
        }
      ]
    }
  ],
  socialLinksEnglish = [
    {
      id: "fl-facebook-link",
      show: true,
      url: "https://www.facebook.com/wafdbank",
      icon: {
        lib: "fab",
        title: "Facebook",
        name: "facebook-f",
        class: "text-white fa-lg"
      }
    },
    {
      id: "fl-twitter-link",
      show: true,
      url: "https://www.twitter.com/wafdbank",
      icon: {
        lib: "fab",
        title: "X (Twitter)",
        name: "xtwitter",
        class: "text-white fa-lg"
      }
    },
    {
      id: "fl-linked-in-link",
      show: true,
      url: "https://www.linkedin.com/company/wafd-bank/",
      icon: {
        lib: "fab",
        title: "LinkedIn",
        name: "linkedin-in",
        class: "text-white fa-lg"
      }
    },
    {
      id: "fl-youtube-link",
      show: true,
      url: "https://www.youtube.com/@WAFDBank?sub_confirmation=1",
      icon: {
        lib: "fab",
        title: "YouTube",
        name: "youtube",
        class: "text-white fa-lg"
      }
    },
    {
      id: "fl-instagram-link",
      show: true,
      url: "https://www.instagram.com/wafdbank/",
      icon: {
        lib: "fab",
        title: "Instagram",
        name: "instagram",
        class: "text-white fa-lg"
      }
    }
  ],
  aboutUsLinksEnglish = [
    {
      id: "mm-ab-about",
      show: true,
      class: "",
      textMobile: "About Us",
      text: "About WaFd Bank",
      url: "/about-us"
    },
    {
      id: "mm-ab-wafd-sports",
      show: true,
      class: "ml-3",
      textMobile: "WaFd Sports",
      text: "WaFd Sports",
      url: "/about-us/wafd-sports"
    },
    {
      id: "mm-ab-reviews",
      show: true,
      class: "ml-3",
      text: "Reviews",
      url: "/about-us/reviews"
    },
    {
      id: "mm-ab-careers",
      show: true,
      class: "ml-3",
      text: "Careers",
      url: "/about-us/banking-careers"
    },
    {
      id: "mm-ab-community-relations",
      show: true,
      class: "ml-3",
      text: "Community Relations",
      url: "/about-us/community-giving"
    },
    {
      id: "mm-ab-investor-relations",
      show: true,
      class: "ml-3",
      text: "Investor Relations",
      url: "/about-us/investor-relations"
    },
    {
      id: "mm-ab-faqs",
      show: true,
      class: "ml-3",
      text: "FAQs",
      url: "/customer-service/faq"
    },
    {
      id: "mm-ab-contact-us",
      show: true,
      class: "ml-3",
      text: "Contact Us",
      url: "/about-us/contact-us"
    },
    {
      id: "bfl-accessibility",
      show: true,
      class: "ml-3",
      text: "Accessibility",
      url: "/accessibility"
    },
    {
      id: "bfl-terms-of-use",
      show: true,
      class: "ml-3",
      text: "Terms of Use",
      url: "/terms-of-use"
    },
    {
      id: "bfl-online-and-mobile-banking-service-agreement",
      show: true,
      class: "ml-3",
      text: "Online Banking Service Agreement",
      url: "/online-and-mobile-banking-service-agreement"
    },
    {
      id: "bfl-ccpa-notice",
      show: true,
      class: "ml-3",
      text: "Notice of Data Collection",
      url: "/privacy-center#ccpa-notice"
    },
    {
      id: "bfl-privacy-security",
      show: true,
      class: "ml-3",
      text: "Privacy Center",
      url: "/privacy-center"
    },
    {
      id: "bfl-cookie-control",
      show: true,
      class: "ml-3",
      text: "Cookie Control",
      url: "/privacy-center#cookie-control",
      icon: "/images/icons/cookie-control-icon.svg"
    }
  ];

module.exports = {
  footerLinksEnglish,
  socialLinksEnglish,
  aboutUsLinksEnglish
};
