import React from "react";

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = (props) => {
  return (
    <>
      {props.fixedWidth ? (
        <span className={`text-center d-inline-block ${props.containerClass}`} style={{ width: props.fixedWidth }}>
          <FontAwesomeIcon
            title={props.title || props.name}
            className={props.class}
            icon={[`${props.lib}`, `${props.name}`]}
            role="presentation"
          />
        </span>
      ) : (
        <FontAwesomeIcon
          title={props.title || props.name}
          className={props.class}
          icon={[`${props.lib}`, `${props.name}`]}
          role="presentation"
        />
      )}
    </>
  );
};
export default Icon;
Icon.defaultProps = {
  containerClass: "mr-2", // only used with fixedWidth, don't add margin to the icon class
  fixedWidth: "", // the width should be about 1rem larger than the size of icon, e.g. icon=1rem width=2rem, icon=2rem, width=3rem
  class: "",
  lib: "fal",
  name: "money-bill-wave"
};
