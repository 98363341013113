import React, { useEffect } from "react";

const UtagOverride = () => {
  useEffect(() => {
    const script = document.createElement("script");
    const scriptText = document.createTextNode(
      '(function(){ window.utag_cfg_ovrd = window.utag_cfg_ovrd || new Object(); window.utag_cfg_ovrd.noview = true; })()'
    );
    script.id = "tealium-utag-override-gatsby";
    script.appendChild(scriptText);
    //const el = document.getElementById("tealium-scripts-wrapper");
    document.body.appendChild(script);

    // cleanup on page tear down
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <></>;
};

export default UtagOverride;
