import React, { useState } from "react";
import { detect } from "detect-browser";
import { Link } from "gatsby";

// Components
import ModalGeneric from "../components/custom-widgets/modal-generic";

const DetectBrowser = () => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  const modalData = {
    handleClose,
    show,
    header: {
      sectionClass: "bg-light text-center border-bottom-5 border-dark",
      class: "mb-2",
      text: "You are using an outdated web browser."
    },
    body: {
      content: [
        <p>
          For a faster and more secure banking experience, we recommend using the latest version of Chrome, Edge or
          Safari.
        </p>
      ]
    },
    footer: {
      class: "bg-light",
      content: [
        <div className="text-right">
          <Link to="/supported-browsers" className="btn btn-primary text-center">
            Upgrade
          </Link>
        </div>
      ]
    }
  };

  const supportedList = ["chrome", "edge", "edge-chromium", "firefox", "safari", "ios"];
  let shouldSeePage = true;
  let isDesktop = true;

  if (typeof window !== "undefined") {
    const mql = window.matchMedia("(min-width: 1024px)");
    isDesktop = mql.matches;
  }
  //console.log("isDesktop: " + isDesktop);
  // restrict browser detection to desktop only
  if (isDesktop) {
    const result = detect();
    supportedList.forEach((browserName) => {
      if (result.name.indexOf(browserName) >= 0) {
        //console.log("found supported browser: " + browserName);
        shouldSeePage = false;
        return false;
      }
    });
    //console.log("shouldSeePage: " + shouldSeePage);
    if (shouldSeePage) {
      let currentTime = Date.now();

      if (typeof window !== "undefined") {
        let storage = window.localStorage;
        let hasSeenRecently = storage.getItem("seenSupportedBrowsers");
        const EXPIRY = 1000 * 60 * 60 * 24 * 7; // 7 days in unix time

        if (hasSeenRecently === null) {
          storage.setItem("seenSupportedBrowsers", true);
          storage.setItem("seenSupportedBrowsersDate", currentTime);
        } else {
          let storedTime = storage.getItem("seenSupportedBrowsersDate");

          if (parseInt(storedTime) + EXPIRY > currentTime) {
            shouldSeePage = false;
          } else {
            storage.removeItem("seenSupportedBrowsers");
          }
        }
      }

      if (show && shouldSeePage) {
        return <ModalGeneric {...modalData} />;
      }
    }
  }
  return null;
};

export default DetectBrowser;
