import React from "react";

import Modal from "react-bootstrap/Modal";

import { useLanguageContext } from "../../contexts/language-context";

/**
 * @param {Object} props
 * @param {boolean} props.show
 * @param {string} props.url
 * @param {Function} props.handleClose
 */

const ExternalModal = (props) => {
  const handleClose = props.handleClose;

  const goExternalLink = () => {
    handleClose();

    if (typeof window !== "undefined") {
      window.open(props.url, "_blank");
    }
  };

  const isSpanish = useLanguageContext();

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton id="external-modal-header">
        <strong>
          {isSpanish ? "Estás saliendo del sitio web de Wafd Bank." : "You're leaving the WaFd Bank website."}
        </strong>
      </Modal.Header>

      <Modal.Body id="external-modal-body">
        <p>
          {isSpanish
            ? `Ha hecho clic en un enlace externo. Haga clic en "Continuar" para continuar o "Cancelar" para permanecer aquí.`
            : `You have clicked an external link. Please click "Proceed" to continue or "Cancel" to stay here.`}
        </p>
      </Modal.Body>

      <Modal.Footer>
        <button className="btn btn-link no-max-width" id="external-modal-cancel-btn" onClick={handleClose}>
          {isSpanish ? "Cancelar" : "Cancel"}
        </button>
        <button className="btn btn-primary no-max-width" id="external-modal-proceed-btn" onClick={goExternalLink}>
          {isSpanish ? "Continuar" : "Proceed"}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExternalModal;
