import React from "react";

// Components
import Modal from "react-bootstrap/Modal";

/**
 * @param {Object} props
 * @param {}
 */

const ModalGeneric = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} size={props.modalSize}>
      {props.header && (
        <Modal.Header className={`${props.header.sectionClass || ""}`} closeButton>
          <Modal.Title id={props.header.id} className={`${props.header.class}`}>
            {props.header.text}
          </Modal.Title>
        </Modal.Header>
      )}

      {props.body && (
        <Modal.Body className={`${props.body.class}`}>{props.body.content.map((component) => component)}</Modal.Body>
      )}

      {props.footer && (
        <Modal.Footer className={`${props.footer.class}`}>
          {props.footer.content.map((component) => component)}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ModalGeneric;

ModalGeneric.defaultProps = {
  id: 1,
  handleClose: null, // close handler callback
  show: false, // open handler callback
  modalSize: "lg" // sm, lg, xl
  // header: {
  //     class: '',
  //     text: ''
  // },
  // body: {
  //     class: '',
  //     content: []
  // },
  // footer: {
  //     class: '',
  //     text: ''
  // }
};

