import React, { useEffect } from "react";
import { useLocation } from "@reach/router";

const ScrollToTopWrapper = ({ children }) => {
  const location = useLocation();

  // We try to scroll up when changing the location
  useEffect(() => {
    if (typeof window !== "undefined") {
      let scrollYEqualToZeroCount = 0;
      const MAX_CHECKS = 3; // 3 is just to be sure that there is not a blink or small jump/change in the scroll

      const forceScrollUp = () => {
        // console.log("I'm forcing scrolling up...");
        window.scrollTo({ top: 0, behavior: "smooth" });

        let checkCount = 0;

        const checkScrollPosition = () => {
          // When we have dynamic content the first time we check scrollY is 0,
          // but after a requestAnimationFrame is not 0 anymore
          let scrollY = window.scrollY;
          // console.log("Checking scroll position", checkCount, "->", scrollY);

          // If we have 0 MAX_CHECKS times then we are sure we are at the very top after
          // the dynamic content is rendered
          if (scrollYEqualToZeroCount === MAX_CHECKS) {
            // console.log("Scroll position was", 0, "->", MAX_CHECKS, " times.");
            return;
          } else {
            // console.log("Scrolling up again...");
            // We have to scroll upper until we reach the top within the dynamic content rendered
            window.scrollTo({ top: 0, behavior: "smooth" });
          }

          // Just a flag to have the information.
          checkCount++;

          if (scrollY === 0) scrollYEqualToZeroCount++;

          // We will check the scroll position after the animation frame as much as we need until we have 0 as value
          // for scrollY MAX_CHECKS times
          setTimeout(() => {
            requestAnimationFrame(checkScrollPosition);
          }, 500); // Tested and it was quick enough
          // The more we wait, the less we call window.scrollTo method and we impact less to the performance
        };

        // This call the whole verification after trying to scroll up the first time.
        checkScrollPosition();
      };

      const scrollUp = () => {
        // console.log("I'm scrolling up...");
        window.scrollTo({ top: 0, behavior: "smooth" });
      };

      // Set scroll restoration to "manual" for custom control
      window.history.scrollRestoration = "manual";

      // console.log("Location:", location);

      // If we have more pages with dynamic content or with issues going up with the scrollUp method
      // we can add it in here, you don't need to add the full location with hash, just the pathname
      const LOCATIONS_TO_FORCE_SCROLL_UP = ["/privacy-center", "/privacy-center/"];

      if (LOCATIONS_TO_FORCE_SCROLL_UP.includes(location.pathname)) forceScrollUp();
      else scrollUp();
    }
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTopWrapper;
